<template>
  <DashboardSubsection :title="locale.title">
    <ElFormItem
      v-if="formats"
      prop="format"
    >
      <TypeSelector
        v-model="model.format"
        :options="formats"
        :disabled="!model._origin.format_edit"
      />
      <p class="text-m-regular">
        <a
          :href="$t('links.inStreamAdsFormat')"
          target="_blank"
        >{{ locale.moreAbout }}</a>
      </p>
    </ElFormItem>
  </DashboardSubsection>
</template>

<script>
import { isSspFormat } from 'lib/helpers/isSsp'

import ElFormItem from 'element/ElFormItem'
import TypeSelector from 'components/form-items/TypeSelector'
import DashboardSubsection from 'components/layouts/DashboardLayout/components/DashboardSubsection'

export default {
  components: {
    DashboardSubsection,
    ElFormItem,
    TypeSelector,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('settings.form.format.title'),
      moreAbout: $locale('settings.form.format.moreAbout'),
    }),
    formats ({ $store }) {
      let formats = $store.getters['dictionaries/campaignDictionaries'].formats

      formats = formats?.filter(format => !isSspFormat(format.id))

      return formats
    },
  },
}
</script>
